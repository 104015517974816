.a-enter-ar-button, .a-enter-vr-button {
    background-size: 90% 90%;
    border: 0;
    cursor: pointer;
    min-width: 58px;
    min-height: 34px;
    padding-right: 0;
    padding-top: 0;
    position: absolute;
    bottom: 100px;
    right: 0;
    transition: background-color .05s ease;
    -webkit-transition: background-color .05s ease;
    z-index: 9999;
    border-radius: 8px;
    touch-action: manipulation;
}